// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import './assets/scss/header';
@import './assets/scss/variables';
// @import './assets/scss/custom-svg';

$DEFAULT_COLOR: #333; //通常文字色
$UI_FRAME_COLOR: #ccc;

html,
body {
  line-height: 1.15;
  letter-spacing: 0.04em;
  background-color: var(--base-color);

  font-family: 'Hiragino Sans', Meiryo, 'Noto Sans CJK JP', sans-serif;

  font-weight: normal;

  // 共通のフォントセットを作成して、各クラスで読み込む
  %fontSet {
    /* ========================================= //
    // 本文フォントファミリー
    // ========================================= */

    &.theme0101,
    &.theme0301,
    &.theme0304,
    &.theme0104 {
      p,
      a,
      ul,
      li,
      span,
      label,
      .jpfont {
        font-family: 'Noto Serif JP', serif;
        font-weight: 300;
      }
    }
    &.theme0302,
    &.theme0303,
    &.theme0201,
    &.theme0202,
    &.theme0102,
    &.theme0103 {
      p,
      a,
      ul,
      li,
      span,
      label,
      .jpfont {
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 400;
      }
    }
    &.theme0203,
    &.theme0204,
    &.theme0105,
    &.theme0305,
    &.theme0205,
    &.theme0206 {
      p,
      a,
      ul,
      li,
      span,
      label,
      .jpfont {
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 700;
      }
    }
    &.theme0306,
    &.theme0307 {
      p,
      a,
      ul,
      li,
      span,
      label,
      .jpfont {
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 400;
      }
    }

    .jpfont {
      ::ng-deep ul > li {
        list-style: disc !important;
        list-style-position: inside !important;
      }
      ul > li {
        list-style: disc !important;
        list-style-position: inside !important;
      }
    }

    /* ========================================= //
    // 見出しフォントファミリー
    // ========================================= */

    // Neutral

    &.theme0101 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Nanum Myeongjo', 'Noto Serif JP', serif;
        font-weight: 400;
      }
      h2 {
        font-size: 30px;
        letter-spacing: 0.06em;
      }
    }

    &.theme0102 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Arvo', 'Noto Sans JP', sans-serif;
        font-weight: 700;
      }
      h2 {
        font-size: 30px;
        letter-spacing: 0.06em;
      }
    }

    &.theme0103 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Noto Sans JP', cursive;
        font-weight: 400;
      }
      h2 {
        font-size: 30px;
        letter-spacing: 0.06em;
      }
    }

    &.theme0104 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Noto Serif', 'Noto Serif JP', cursive;
        font-weight: 400;
      }
      h2 {
        font-size: 30px;
        letter-spacing: 0.06em;
      }
    }

    &.theme0105 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Arvo', 'Noto Sans JP', sans-serif;
        font-weight: 700;
      }
      h2 {
        font-size: 30px;
        letter-spacing: 0.06em;
      }
    }

    // Unique

    &.theme0201 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Amatic SC', 'Noto Sans JP', sans-serif;
        font-weight: 700;
      }
      h2 {
        font-size: 45px;
        letter-spacing: 0.06em;
      }
    }

    &.theme0202 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Caveat', 'Noto Sans JP', sans-serif;
        font-weight: 700;
      }
      h2 {
        font-size: 38px;
        letter-spacing: 0.06em;
      }
    }

    &.theme0203 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Amatic SC', 'Noto Sans JP', sans-serif;
        font-weight: 700;
      }
      h2 {
        font-size: 30px;
        letter-spacing: 0.06em;
      }
    }

    &.theme0204 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Caveat', 'Noto Sans JP', sans-serif;
        font-weight: 700;
      }
      h2 {
        font-size: 38px;
        letter-spacing: 0.06em;
      }
    }

    &.theme0205 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Delius Swash Caps', 'Noto Sans JP', sans-serif;
        font-weight: 400;
      }
      h2 {
        font-size: 30px;
        letter-spacing: 0.06em;
      }
    }

    &.theme0206 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Courgette', 'Noto Sans JP', sans-serif;
        font-weight: 400;
      }
      h2 {
        font-size: 30px;
        letter-spacing: 0.06em;
      }
    }

    // Modern

    &.theme0301 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Marcellus', 'Noto Serif JP', serif;
        font-weight: 400;
      }
      h2 {
        font-size: 30px;
        letter-spacing: 0.06em;
      }
    }

    &.theme0302 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Josefin Sans', 'Noto Sans JP', sans-serif;
        font-weight: 400;
      }
      h2 {
        font-size: 30px;
        letter-spacing: 0.06em;
      }
    }

    &.theme0303 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Josefin Sans', 'Noto Sans JP', sans-serif;
        font-weight: 300;
      }
      h2 {
        font-size: 30px;
        letter-spacing: 0.06em;
      }
    }

    &.theme0304 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Abril Fatface', 'Noto Serif JP', cursive;
        font-weight: 400;
      }
      h2 {
        font-size: 30px;
        letter-spacing: 0.06em;
      }
    }

    &.theme0305 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Josefin Sans', 'Noto Sans JP', sans-serif;
        font-weight: 600;
        font-style: italic;
      }
      h2 {
        font-size: 30px;
        letter-spacing: 0.06em;
      }
    }

    &.theme0306 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Staatliches', 'Noto Sans JP', cursive;
        font-weight: 400;
      }
      h2 {
        font-size: 39px;
        letter-spacing: 0.06em;
      }
    }

    &.theme0307 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .enfont {
        font-family: 'Comfortaa', 'Noto Sans JP', sans-serif;
        font-weight: 400;
      }
      h2 {
        font-size: 30px;
        letter-spacing: 0.06em;
      }
    }

    /* ========================================= //
    // 見出しスタイル
    // ========================================= */
    &.h_theme0101 {
      .secHeading::after {
        display: none;
      }
    }

    &.h_theme0102 {
      .secHeading::after {
        display: block;
        bottom: 8px;
      }
    }

    &.h_theme0103 {
      .secHeading::after {
        transform: rotate(90deg);
        bottom: 8px;
      }
    }

    &.h_theme0201 {
      .secHeading::after {
        display: block;
        mask-image: url('/assets/images/icons/h-border0201.svg');
        -webkit-mask-image: url('/assets/images/icons/h-border0201.svg');
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        width: 144px;
        height: 10px;
        bottom: 5px;
      }
    }

    &.h_theme0202 {
      .secHeading::after {
        display: block;
        mask-image: url('/assets/images/icons/h-border0202.svg');
        -webkit-mask-image: url('/assets/images/icons/h-border0202.svg');
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        width: 144px;
        height: 15px;
        bottom: 5px;
      }
    }

    &.h_theme0203 {
      .secHeading::after {
        display: block;
        width: 144px;
        height: 4px;
        bottom: 12px;
      }
    }

    &.h_theme0204 {
      .secHeading::after {
        display: block;
        mask-image: url('/assets/images/icons/h-border0204.svg');
        -webkit-mask-image: url('/assets/images/icons/h-border0204.svg');
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        width: 73px;
        height: 10px;
        bottom: 5px;
      }
    }

    &.h_theme0205 {
      .secHeading::after {
        display: block;
        mask-image: url('/assets/images/icons/h-border0205.svg');
        -webkit-mask-image: url('/assets/images/icons/h-border0205.svg');
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        width: 73px;
        height: 10px;
        bottom: 5px;
      }
    }

    &.h_theme0301 {
      .secHeading::after {
        display: none;
      }
    }

    &.h_theme0302 {
      .secHeading::after {
        display: block;
        width: 144px;
        height: 2px;
        bottom: 10px;
      }
    }

    &.h_theme0303 {
      .secHeading {
        line-height: 45px;
        display: inline-block;
        width: unset;
        border-bottom: solid 1.3px var(--accent-color);
        padding-bottom: 0;
        margin-bottom: 35px;
      }
      .secHeading::after {
        display: none;
      }
      .topNews__subHeading {
        width: 100%;
      }
      .storeDetailHeading__desc {
        width: 100%;
      }
      .staffHeading__desc {
        width: 100%;
      }
      .galleryHeading__desc {
        width: 100%;
      }
      .newsHeading__desc {
        width: 100%;
      }
      .campaignHeading__desc {
        width: 100%;
      }
    }
  }
  .app-body {
    .ion-page {
      // フォントセット読み込み
      @extend %fontSet;
    }
    .modal-body {
      // フォントセット読み込み
      @extend %fontSet;
    }
  }

  // &.ios {
  //   .body-offset-for-open-card-modal {
  //     & > .ion-page {
  //       top: -6px;
  //     }
  //   }
  // }

  // 既存のcard modalだと上部の余白が大きいので、システム用に独自に高さ調整
  .body-offset-for-open-card-modal {
    // $offsetY: 25px;
    // transform: translateY(-#{$offsetY});
    // height: calc(100% + #{$offsetY});
    // max-height: calc(100% + #{$offsetY});
    // @media #{ $pcview } {
    //   transform: translateY(0);
    //   height: 100%;
    //   max-height: 100%;
    // }

    .card-modal {
      &.__noIos__ {
        // top: 25px;
        --width: 100%;
        --max-width: 1024px;
        --height: 100%;
        --border-radius: 0;
        @media screen and (min-width: 768px) {
          top: 0;
          --width: 90%;
          --max-width: 1024px;
          --height: 90%;
          --border-radius: 7px;
        }
        @media #{ $pcview } {
          top: 0;
          --width: 90%;
          --max-width: 1024px;
          --height: 90%;
          --border-radius: 7px;
        }
      }
    }
  }
}

html,
body,
ion-app,
ion-content {
  user-select: auto;
}

html.plt-mobile ion-app {
  user-select: auto;
}
html.plt-pwa {
  height: 100%;
  touch-action: none;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.overflow_scroll {
  overflow-y: scroll;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  filter: none;
  transition: filter 0.2s;

  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
  margin-right: constant(safe-area-inset-right);
  margin-right: env(safe-area-inset-right);
  margin-left: constant(safe-area-inset-left);
  margin-left: env(safe-area-inset-left);
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  margin: 0 auto 30px auto;
  padding: 0 15px;
  max-width: 1024px;
  width: calc(100% - 10px);
  &_item {
    display: block;
    text-decoration-line: none;
    color: var(--functional-color);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: auto;
    overflow-x: hidden;
    font-size: 10px;
    line-height: 18px;
    max-width: 120px;
    cursor: pointer;
    @media #{ $pcview } {
      font-size: 13px;
    }
    &:last-of-type {
      pointer-events: none;
    }
    &.home {
      mask-image: url('/assets/images/icons/icon-home.svg');
      -webkit-mask-image: url('/assets/images/icons/icon-home.svg');
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-size: cover;
      -webkit-mask-size: cover;
      background-color: var(--functional-color);
      width: 13px;
      height: 12px;
      min-width: 13px;
      @media #{ $pcview } {
        width: 15px;
        height: 14px;
        min-width: 15px;
      }
    }
    &.__modalStyle__ {
      color: var(--functional-color-modal);
      &.home {
        background-color: var(--functional-color-modal);
      }
    }
  }
  &_arrow {
    display: block;
    text-decoration-line: none;
    color: var(--functional-color);
    font-family: 'Noto Serif JP', serif !important;
    font-weight: 300 !important;
    min-width: 30px;
    text-align: center;
    font-size: 10px;
    line-height: 18px;
    @media #{ $pcview } {
      font-size: 13px;
    }
    &.__modalStyle__ {
      color: var(--functional-color-modal);
    }
  }
}

.stop_scroll {
  overflow-y: hidden;
}

.header_content {
  display: none;
  @media #{ $pcview } {
    animation: unset;
    opacity: 1;
    display: block;
  }
}

.header_shopDetailModalContent {
  display: none;
}

.active {
  animation: fadein 0.5s;
  opacity: 1;
  display: block;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.background_none {
  background: unset;
}

.blur_active {
  filter: blur(10px);
  transition: filter 0.4s;
}

.hide {
  opacity: 0;
}

.fadeInAll {
  animation: fadein 0.5s;
}

.separate {
  width: 90%;
  max-width: 672px;
  background-color: var(--accent-color);
  margin: 0 auto;
}

//applike時のみ適用する変更
.applike {
  .snsIcons {
    padding-bottom: unset !important;
  }
  .footer {
    border-top: none !important;
    &_container {
      @media ($ipadv) {
        padding-top: 32px;
      }
    }

    &_heading {
      display: none;
    }

    &_nav {
      &__list {
        @media ($ipadv) {
          margin-bottom: 30px;
          max-width: 672px;
        }
      }

      &__item {
        @media ($ipadv) {
          width: auto;
          margin-bottom: 0;
        }
        @media #{ $pcview } {
          margin: 0;
        }
        &--about {
          display: none;
          @media ($ipadv) {
            display: block;
          }
        }
      }

      &__link {
        @media ($ipadv) {
          font-size: 12px;
          padding: 0 12.9px;
          letter-spacing: 0.1em;
        }
      }
    }

    &_copyright {
      &__line {
        @media ($ipadv) {
          font-size: 9px;
        }
      }
    }
  }
}

@keyframes reveal {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(200%);
  }
}

@keyframes revealPic {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media #{ $pcview } {
  .reveal {
    display: block;
    animation: reveal 1s cubic-bezier(0.06, 0.64, 0.91, 0.29);
    animation-fill-mode: forwards;
    background: var(--base-color);
    position: absolute;
    width: 200%;
    height: 100%;
    z-index: 1;
    right: 0;
    left: 0;
    top: 0;
    &::before {
      content: '';
      display: block;
      background: var(--accent-color);
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      left: -100%;
      top: 0;
    }
    &.__modalStyle__ {
      background: var(--base-color-modal);
      &::before {
        background: var(--accent-color-modal);
      }
    }
  }
  .reveal_pic {
    display: block;
    animation: revealPic 0.6s cubic-bezier(0.59, 0, 0.36, 0.81);
    animation-fill-mode: forwards;
    background: var(--base-color);
    position: absolute;
    width: 200%;
    height: 100%;
    z-index: 1;
    right: 0;
    left: 0;
    top: 0;
    &.__modalStyle__ {
      background: var(--base-color-modal);
    }
  }
}

/* モーダルダイアログ */
.dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  padding: 15px;
  padding-top: calc(constant(safe-area-inset-top) + 15px);
  padding-bottom: calc(constant(safe-area-inset-bottom) + 15px);
  padding-top: calc(env(safe-area-inset-top) + 15px);
  padding-bottom: calc(env(safe-area-inset-top) + 15px);
  background: rgba(#999, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  // ダイアログの全体スクロールコンテナ
  .dialog {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    font-size: 14px;
    line-height: 1.4em;
    .container {
      padding: 0 15px;
    }
  }

  .header-controls {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 32px;
    margin-bottom: 8px;
    background: #fff;
    z-index: 20;
    > * {
      flex: 1 1 auto;
      font-size: 16px;
    }
    &.title-bar {
      height: 44px;
    }
    .title {
      flex: 1 1 auto;
      text-align: center;
    }
    .buttons {
      flex: 1 0 40px;
      min-width: 40px;
      display: flex;
      align-items: center;
      &.align-right {
        justify-content: flex-end;
      }
      ion-button {
        --padding-start: 2px;
        --padding-end: 2px;
        min-width: 32px;
        font-size: 16px;
        height: auto;
        margin: 0;
      }
      .close-button {
        width: 32px;
      }
    }
  }

  .header-controls {
    & + .dialog-title {
      padding-top: 0;
    }
  }

  .dialog-title {
    font-size: 24px;
    padding: 8px 0;
    text-align: center;
  }

  .dialog-body {
    font-size: 14px;
    line-height: 1.4em;
  }

  .dialog-button {
    width: 100%;
    height: 44px;
    font-size: 20px;
    margin: 0 0 15px 0;
    --border-radius: 2px;
    --box-shadow: none;
    &.transparent {
      --padding-start: 0;
      --padding-end: 0;
    }
  }

  .section-title {
    font-weight: bold;
    margin-bottom: 0.3em;
  }

  .section-block {
    padding: 15px 0;
    &.half-padding {
      padding: 8px 0;
    }
    &.quarter-padding {
      padding: 4px 0;
    }
    &.border {
      border-bottom: 1px solid #ccc;
    }
  }
}

// Android用A2HSバナー
.a2hsForA {
  --ion-background-color: white !important;
  position: absolute;
  bottom: 0;
  &_toolbar {
    padding-top: 8px;
    &:last-child {
      padding-bottom: calc(var(--ion-safe-area-bottom, 0) + 8px);
    }
  }
  &_logo {
    margin-right: 15px;
    margin-left: 10px;
    &_img {
      width: 20px;
    }
  }
  &_txt {
    color: #3880ff;
    font-family: 'Hiragino Sans', Meiryo, 'Noto Sans CJK JP', sans-serif !important;
    font-size: 16px;
    font-weight: normal !important;
    &.bold {
      font-weight: bold !important;
    }
  }
  &_close {
    margin-right: 15px;
    &_btn {
      width: 20px;
    }
  }
}

// Android用A2HSバルーン（店舗詳細モーダル）
.forShop {
  .a2hsForA {
    bottom: 15px !important;
  }
}

// iPhone用A2HSバルーン（店舗詳細モーダル）
.forShop {
  .mobileAction_balloonForiOS {
    bottom: 25px !important;
  }
}

/**
 * カスタムマークリスト
 * 必要に応じて追加する
 */
ul.custom-mark {
  li {
    position: relative;
    font-size: 1em;
    padding-left: 1.15em;
    margin-bottom: 0.3em;
    font-family: $default_font_family;
  }
  &.bold {
    li {
      font-weight: bold;
      &::before {
        font-weight: bold;
      }
    }
  }
  &.mark-double-circle {
    li {
      background: none;
      &::before {
        position: absolute;
        content: '◎';
        top: 0;
        left: 0;
        opacity: 0.5;
      }
    }
  }
}

// ul liがリセットされているのでchromeのユーザーエージェントシート
ul.default {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  li {
    list-style-type: disc;
    display: list-item;
    text-align: -webkit-match-parent;
    font-family: $default_font_family;
  }
  &.margin-y-2 {
    li {
      margin: 0.5em 0;
    }
  }
  &.margin-y-4 {
    li {
      margin: 1em 0;
    }
  }
}

// デフォルトのspanタグ
span.default {
  font-family: $default_font_family;
}

// デフォルトのaタグ
a.default {
  font-family: $default_font_family;
  text-decoration: underline;
}

//マイページ機能内
//ion-inputフォーカス
.has-focus.sc-ion-input-ios-h,
.has-focus.sc-ion-input-md-h {
  box-shadow: 0px 0px 5px rgba(#3399ff, 0.8);
  border: 2px solid #3399ff !important;
}

.alert-wrapper.sc-ion-alert-ios,
.alert-wrapper.sc-ion-alert-md {
  font-family: $default_font_family;
}

// androidとiosでアラートボタンの縦並び順が逆になっているので揃える
.alert-button-group.sc-ion-alert-ios,
.alert-button-group.sc-ion-alert-md {
  flex-wrap: wrap-reverse;
}

//黒枠線のボタン
.ion-color-light-button {
  border: 1px solid #ccc;
}

.progress-mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: rgba(#fff, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  &.dark {
    background: rgba(#eee, 0.5);
  }

  &.opaque {
    background: #fff;
  }

  &.transparent {
    background: transparent;
  }

  ion-spinner {
    color: #000;
  }
}

// attention-colorのボタン
.attention-color-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: fit-content;
  padding: 0 24px;
  font-size: 14px;
  line-height: 1.3em;
  text-align: center;
  white-space: nowrap;
  color: var(--base-color);
  background-color: var(--attention-color);
  border-radius: 36px;
  border: 1px solid var(--attention-color);
  transition: all 0.4s ease;
  cursor: pointer;
  &:hover {
    color: var(--attention-color);
    background-color: transparent;
    transition: all 0.4s ease;
  }
}
// functional-colorのボタン
.functional-color-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: fit-content;
  padding: 0 24px;
  font-size: 14px;
  line-height: 1.3em;
  text-align: center;
  white-space: nowrap;
  color: var(--functional-color);
  background-color: transparent;
  border-radius: 36px;
  border: 1px solid var(--accent-color);
  transition: all 0.4s ease;
  cursor: pointer;
  &:hover {
    color: var(--base-color);
    background-color: var(--functional-color);
    transition: all 0.4s ease;
  }
}

//------------------ 補助クラス -----------------
.bold {
  font-weight: bold !important;
}

.color-red {
  color: #f00 !important;
}

.align-center {
  text-align: center !important;
}

.align-left {
  text-align: left !important;
}

// reservationAppとの共通ボタン
button {
  &.button {
    margin-right: 7px;
    margin-bottom: 15px;
    padding: 5px 10px;
    color: $DEFAULT_COLOR;
    background: #fff;
    border: 1px solid $UI_FRAME_COLOR;
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    border-radius: 2px;
  }
}

// reservationAppと共通のスペース
.verticalSpacer {
  height: 15px;
  &.exSmall {
    height: 5px;
  }
  &.small {
    height: 10px;
  }
  &.large {
    height: 15px;
  }
  &.h30px {
    height: 30px;
  }
  &.h60px {
    height: 60px;
  }
  &.h150px {
    height: 150px;
  }
  &.h200px {
    height: 200px;
  }
  &.h300px {
    height: 300px;
  }
}

.map-container {
  height: 100% !important;
  width: 100% !important;
}

$spv: 'min-width: 480px';
// タブレット・PC用（ギャラリーのモーダル横幅など）
$ipadv: 'min-width: 768px';
$laptop: 'min-width: 1280px';
$desktop: 'min-width: 1920px';

$sptabview: 'min-width: 481px';
// スマホ表示・PC表示の出しわけ　現状は、iPadPro最大サイズ（1366px）以下かつ横向き（landscape）の場合はPC表示、iPadPro最大サイズ（1024px）以下かつ縦向き（Portrait）の場合はスマホ表示
$pcview: '(orientation: landscape) and (min-width: 1367px) , (orientation: portrait) and (min-width: 1025px)';

// A2HSバナーの出しわけ（iOSのみ）
$ipadpro: 'min-width: 834px';
$ipadpro12: 'min-width: 1024px';

$default_font_family: 'Hiragino Sans', Meiryo, 'Noto Sans CJK JP', sans-serif !important;

// 余白サイズ
$double_padding: 32px;
$basic_padding: 16px;
$half_padding: 8px;
$quarter_padding: 4px;

:root {
  // モジュールの目立つ場所に使用するかカラー
  --accent-color: '';
  // テキスト/ヘッダ-ロゴ/ボーダー/ボタンなどに使用するかカラー
  --functional-color: '';
  // スタッフの役職/ニュースの日付/ページ内リンクボタンの枠線/Ajaxローディングボタンの枠線に割り当てられているカラー。
  --lighten-20-color: '';
  // ボーダー/モーダルのnext-prevボタンに使用
  --lighten-50-color: '';
  // 背景/反転したテキスト/ヘッダー帯などに使用されるカラー
  --base-color: '';
  // 予約に関連するモジュールで使用するカラー
  --attention-color: '';

  // モジュールの目立つ場所に使用するかカラー（modal用）
  --accent-color-modal: '';
  // テキスト/ヘッダ-ロゴ/ボーダー/ボタンなどに使用するかカラー（modal用）
  --functional-color-modal: '';
  // スタッフの役職/ニュースの日付/ページ内リンクボタンの枠線/Ajaxローディングボタンの枠線に割り当てられているカラー（modal用）
  --lighten-20-color-modal: '';
  // ボーダー/モーダルのnext-prevボタンに使用（modal用）
  --lighten-50-color-modal: '';
  // 背景/反転したテキスト/ヘッダー帯などに使用されるカラー（modal用）
  --base-color-modal: '';
  // 予約に関連するモジュールで使用するカラー（modal用）
  --attention-color-modal: '';
  // デフォルトフォントを設定
  --ion-font-family: #{$default_font_family};
}

/* ========================================= //
// メニュー詳細　コンテンツ
// ========================================= */

$menu_container_width_percent: 84%; //メニューコンテナの幅（％）
$menu_container_width_value: 0.84; //メニューコンテナ幅（小数）
$menu_container_max_width: 672px; //メニューコンテナの最大幅
$category_selector_width: 70px; // カテゴリ選択サイドバーの基本幅
$category_selector_max_width: calc(#{$category_selector_width} * 4);
$category_bar_child_margin: 20px; // category_bar内の子要素の左右間隔
$menu_item_child_margin: 30px; // menu_item内の子要素の左右間隔（PC）
$menu_item_child_margin_sp: 20px; // menu_item内の子要素の左右間隔（SP）
$buttons_height: 44px; // もっと見るボタン郡の高さ

/* ========================================= //
// アスペクト比固定画像
// ========================================= */
///* <div class="aspectImg ratio-2x1">
//     <div class="aspectImg__inner" style="background-image: url()"></div>
//   </div>
//*/
.aspectImg {
  display: block;
  position: relative;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  height: auto;
  background: var(--base-color);
  &.__modalStyle__ {
    background: var(--base-color-modal);
  }
  &::before {
    content: '';
    display: block;
  }

  /* 2:1のアスペクト比 */
  &.ratio_2x1 {
    &::before {
      padding-top: 50%; /* 2:1 = 1/2*100% */
    }
  }

  /* 1:1のアスペクト比 */
  &.ratio_1x1 {
    &::before {
      padding-top: 100%; /* 1:1 = 1/1*100% */
    }
  }

  /* 5:6のアスペクト比 */
  &.ratio_5x6 {
    &::before {
      padding-top: 120%; /* 5:6 = 6/5*100% */
    }
  }

  /* 背景画像部分 */
  &__inner {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &.ng-lazyloading,
    &.ng-failed-lazyloaded {
      background-size: 30px !important;
      @media ($ipadv) {
        background-size: 85px !important;
      }
    }
  }
}

/* ========================================= //
// ボタン
// ========================================= */

.btn_more {
  display: block;
  border-radius: 36px;
  border: 0.5px solid var(--accent-color);
  width: 174px;
  // padding-top: 3px;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  color: var(--functional-color);
  background-color: transparent;
  text-decoration: none;
  transition: all 0.4s ease;
  padding: 3px;
  cursor: pointer;
  &:hover {
    color: var(--base-color);
    background-color: var(--functional-color);
    text-decoration: none;
    transition: all 0.4s ease;
  }
  &.__modalStyle__ {
    border: 0.5px solid var(--accent-color-modal);
    color: var(--functional-color-modal);
    &:hover {
      color: var(--base-color-modal);
      background-color: var(--functional-color-modal);
    }
  }
}

.btn_accessMobileBottom {
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 36px;
  border: 0.5px solid var(--accent-color);
  flex: 1 1 40%;
  height: 35px;
  padding-bottom: 2px;
  text-align: center;
  font-size: 14px;
  color: var(--functional-color);
  background-color: var(--base-color);
  text-decoration: none;
  transition: all 0.4s ease;
  cursor: pointer;
  &:hover {
    color: var(--base-color);
    background-color: var(--functional-color);
    text-decoration: none;
    transition: all 0.4s ease;
  }
  &.__modalStyle__ {
    border: 0.5px solid var(--accent-color-modal);
    color: var(--functional-color-modal);
    background-color: var(--base-color-modal);
    &:hover {
      color: var(--base-color-modal);
      background-color: var(--functional-color-modal);
    }
  }
  &.showBtn {
    display: flex;
  }
}

.btn_shopListMobileBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 36px;
  border: 0.5px solid var(--accent-color);
  flex: 1 1 40%;
  height: 35px;
  padding-bottom: 2px;
  text-align: center;
  font-size: 14px;
  color: var(--functional-color);
  background-color: var(--base-color);
  text-decoration: none;
  transition: all 0.4s ease;
  cursor: pointer;
  &:hover {
    color: var(--base-color);
    background-color: var(--functional-color);
    text-decoration: none;
    transition: all 0.4s ease;
  }
  &.__modalStyle__ {
    border: 0.5px solid var(--accent-color-modal);
    color: var(--functional-color-modal);
    background-color: var(--base-color-modal);
    &:hover {
      color: var(--base-color-modal);
      background-color: var(--functional-color-modal);
    }
  }
}

.btn_bookMobileBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 36px;
  border: 0.5px solid var(--attention-color);
  flex: 1 1 55%;
  max-width: 55%;
  height: 35px;
  padding-bottom: 2px;
  text-align: center;
  font-size: 14px;
  color: var(--base-color);
  background-color: var(--attention-color);
  text-decoration: none;
  transition: all 0.4s ease;
  cursor: pointer;
  &:hover {
    color: var(--attention-color);
    background-color: var(--base-color);
    text-decoration: none;
    transition: all 0.4s ease;
  }
  &.__modalStyle__ {
    border: 0.5px solid var(--attention-color-modal);
    color: var(--base-color-modal);
    background-color: var(--attention-color-modal);
    &:hover {
      color: var(--attention-color-modal);
      background-color: var(--base-color-modal);
    }
  }
}

.btn_otherHeader {
  display: block;
  color: var(--functional-color);
  text-decoration: none;
  transition: all 0.4s ease;
  font-size: 14px;
  mask-image: url('/assets/images/icons/icon-menuOther.svg');
  -webkit-mask-image: url('/assets/images/icons/icon-menuOther.svg');
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: cover;
  -webkit-mask-size: cover;
  cursor: pointer;
  background-color: var(--functional-color);
  width: 20px;
  height: 20px;
  min-width: 20px;
  position: relative;
  top: 3px;
  &.__modalStyle__ {
    color: var(--functional-color-modal);
    background-color: var(--functional-color-modal);
  }
}

.btn_shopListMapHeader {
  display: block;
  border-radius: 36px;
  border: 0.5px solid var(--accent-color);
  width: 129px;
  padding: 6px;
  text-align: center;
  color: var(--functional-color);
  background-color: transparent;
  text-decoration: none;
  transition: all 0.4s ease;
  font-size: 14px;
  height: 30px;
  &:hover {
    color: var(--base-color);
    background-color: var(--functional-color);
    text-decoration: none;
    transition: all 0.4s ease;
  }
  &.__modalStyle__ {
    border: 0.5px solid var(--accent-color-modal);
    color: var(--functional-color-modal);
    &:hover {
      color: var(--base-color-modal);
      background-color: var(--functional-color-modal);
    }
  }
}

.btn_bookHeader {
  display: block;
  border-radius: 36px;
  border: 0.5px solid var(--attention-color);
  width: 129px;
  padding: 6px;
  text-align: center;
  color: var(--base-color);
  background-color: var(--attention-color);
  text-decoration: none;
  transition: all 0.4s ease;
  font-size: 14px;
  height: 30px;
  &:hover {
    color: var(--attention-color);
    background-color: transparent;
    text-decoration: none;
    transition: all 0.4s ease;
  }
  &.__modalStyle__ {
    border: 0.5px solid var(--attention-color-modal);
    color: var(--base-color-modal);
    background-color: var(--attention-color-modal);
    &:hover {
      color: var(--attention-color-modal);
      background-color: var(--base-color-modal);
    }
  }
}

.btn_bookShimei {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 36px;
  border: 0.5px solid var(--attention-color);
  width: 100%;
  height: 40px;
  // padding-top: 5px;
  padding-bottom: 2px;
  text-align: center;
  font-size: 14px;
  color: var(--base-color);
  background-color: var(--attention-color);
  text-decoration: none;
  transition: all 0.4s ease;
  cursor: pointer;
  @media #{ $pcview } {
    width: 239px;
    height: 35px;
    margin: 0 auto;
    // padding-top: 1px;
  }
  &:hover {
    color: var(--attention-color);
    background-color: transparent;
    text-decoration: none;
    transition: all 0.4s ease;
  }
  &__elseContent {
    @media #{ $pcview } {
      width: 320px;
    }
  }
  &.__modalStyle__ {
    border: 0.5px solid var(--attention-color-modal);
    color: var(--base-color-modal);
    background-color: var(--attention-color-modal);
    &:hover {
      color: var(--attention-color-modal);
      background-color: var(--base-color-modal);
    }
  }
}

.btn_checkDetail {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 36px;
  border: 0.8px solid var(--accent-color);
  width: 100%;
  height: 40px;
  padding-bottom: 2px;
  text-align: center;
  font-size: 14px;
  color: var(--functional-color);
  background-color: transparent;
  text-decoration: none;
  transition: all 0.4s ease;
  cursor: pointer;
  @media #{ $pcview } {
    width: 240px;
    height: 35px;
  }
  &:hover {
    color: var(--base-color);
    background-color: var(--functional-color);
    text-decoration: none;
    transition: all 0.4s ease;
  }
  &__elseContent {
    @media #{ $pcview } {
      width: 320px;
    }
  }
  &.__modalStyle__ {
    border: 0.8px solid var(--accent-color-modal);
    color: var(--functional-color-modal);
    &:hover {
      color: var(--base-color-modal);
      background-color: var(--functional-color-modal);
    }
  }
}

.btn_checkDetailNewTab {
  display: block;
  border-radius: 36px;
  border: 0.5px solid var(--accent-color);
  width: 100%;
  height: 40px;
  padding-top: 4px;
  text-align: center;
  font-size: 14px;
  color: var(--functional-color);
  background-color: transparent;
  text-decoration: none;
  transition: all 0.4s ease;
  @media #{ $pcview } {
    width: 331px;
    height: 35px;
    padding-top: 1px;
  }

  .openNewTabSVG {
    position: relative;
    top: 2px;
    path {
      stroke: unset;
      fill: unset;
      transition: all 0.4s ease;
    }
  }

  &:hover {
    color: var(--base-color);
    background-color: var(--functional-color);
    text-decoration: none;
    transition: all 0.4s ease;
    .openNewTabSVG path {
      stroke: var(--base-color);
      fill: var(--base-color);
      stroke-width: 0.5;
      transition: all 0.4s ease;
    }
  }

  img {
    position: relative;
    top: 4px;
    margin-left: 4px;
    width: 14px;
    height: auto;
    @media #{ $pcview } {
      top: 3px;
    }
  }
  &.__modalStyle__ {
    border: 0.5px solid var(--accent-color-modal);
    color: var(--functional-color-modal);
    &:hover {
      color: var(--base-color-modal);
      background-color: var(--functional-color-modal);
      .openNewTabSVG path {
        stroke: var(--base-color-modal);
        fill: var(--base-color-modal);
      }
    }
  }
}

.btn_moreSquare {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 0.5px solid var(--accent-color);
  height: 42px;
  text-align: center;
  font-size: 14px;
  color: var(--functional-color);
  background-color: transparent;
  text-decoration: none;
  transition: all 0.4s ease;
  &:hover {
    text-decoration: none;
    background-color: var(--functional-color);
    color: var(--base-color);
    transition: all 0.4s ease;
  }
  &.__modalStyle__ {
    border: 0.5px solid var(--accent-color-modal);
    color: var(--functional-color-modal);
    &:hover {
      background-color: var(--functional-color-modal);
      color: var(--base-color-modal);
    }
  }
}

.mobileAction {
  &_balloonForiPad {
    position: absolute;
    width: 370px;
    top: 15px;
    right: 15px;
    padding: 10px 15px 15px 15px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    z-index: 999;

    &::before {
      position: absolute;
      content: '';
      border-radius: 50%;
      background: #fff;
      top: -12px;
      z-index: -1;
      width: 50px;
      height: 50px;
      right: 56px;
      border: none;
    }

    &::after {
      position: absolute;
      content: '';
      width: 8px;
      height: 8px;
      border-top: 2px solid #bbb;
      border-left: 2px solid #bbb;
      transform: rotate(45deg);
      top: 0;
      right: 76px;
    }

    &_A2HS {
      position: relative;
      width: 25px;
      height: 25px;
      top: 2px;
      margin-right: 0.2em;
    }

    &_texts {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &_text {
      display: inline-block;
      position: relative;
      font-size: 14px !important;
      font-weight: normal !important;
      font-family: 'Hiragino Sans', Meiryo, 'Noto Sans CJK JP', sans-serif !important;
      line-height: 1.6em; //ボタンの高さ分の行間を確保するため行間を確保
      margin: 0;
      text-align: left;
      &.bold {
        font-weight: bold !important;
      }
    }

    &_okButton {
      position: absolute;
      text-decoration: none;
      border: 1px solid #ccc;
      padding: 2px 10px;
      border-radius: 10px;
      font-size: 12px;
      text-align: center;
      font-family: inherit !important;
      right: 0;
      bottom: 0;
      line-height: 1.2em;
      color: #000;
      transition: all 0.4s ease;
    }
  }
}

.blockArea {
  width: 100%;
  // ページの最後にブロックエリアがある場合、margin-bottom削除
  &.lastMarginBottomTrim {
    .block {
      &:last-of-type {
        > .blockContent > div {
          margin-bottom: 0;
        }
      }
    }
  }
}

// ページの最後に表示される要素が確定していない場合に、子要素の最後のmargin-bottomを削除してくれるクラス
// 確定しない要素たちが同じ階層に表示されていることが前提
// ブロックエリアの修正が完了していないので、ベータ版リリース後に反映されるように修正を行う予定
.rmMbLastContents {
  > :last-child {
    margin-bottom: 0;
    &.blockArea {
      > app-block > .block:last-of-type  {
        > .blockContent {
          // 基本ブロック
          &.title,
          &.image,
          &.button,
          &.shopLink,
          &.text,
          &.line,
          // 表ブロック、紹介ブロックの場合
          &.table,
          &.introduction {
            > :first-child {
              margin-bottom: 0;
            }
          }
          // ２列ブロックの場合
          &.column {
            > .block__columnGroup {
              &.column2 {
                // ２列目の最後のブロック要素からmargin-bottomを削除
                > .block__column:nth-of-type(2) {
                  > .column__block:last-of-type > .column__blockContent > :first-of-type {
                    margin-bottom: 0;
                  }
                }
                // ２列目が空の時、１列目の最後のブロック要素からmargin-bottomを削除
                > .block__column:nth-of-type(1):has(+ .block__column:nth-of-type(2):empty) {
                  > .column__block:last-of-type > .column__blockContent > :first-of-type {
                    margin-bottom: 0;
                  }
                }
                // ２列表示の場合はどちらの列も、最後のブロック要素からmargin-bottomを削除
                @media ($ipadv) {
                  > .block__column {
                    > .column__block:last-of-type > .column__blockContent > :first-of-type {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // ブロック要素ではない場合、rmMbTargetに対してmargin-bottomを削除
    &:not(.blockArea) {
      .rmMbTarget {
        margin-bottom: 0;
      }
    }
  }
}
// グループとしてのrmMbLastContentsを適用する場合
.rmMbLastContentsGroup {
  // rmMbLastContentsGroupの子要素でrmMbLastContentsが割り当てられる最後の要素
  > :last-child {
    // その中の最後の要素（以降はrmMbLastContentsのスタイル指定と同じ）
    > :last-child {
      margin-bottom: 0;
      &.blockArea {
        > app-block > .block:last-of-type  {
          > .blockContent {
            // 基本ブロック
            &.title,
            &.image,
            &.button,
            &.shopLink,
            &.text,
            &.line,
            // 表ブロック、紹介ブロックの場合
            &.table,
            &.introduction {
              > :first-child {
                margin-bottom: 0;
              }
            }
            // ２列ブロックの場合
            &.column {
              > .block__columnGroup {
                &.column2 {
                  // ２列目の最後のブロック要素からmargin-bottomを削除
                  > .block__column:nth-of-type(2) {
                    > .column__block:last-of-type > .column__blockContent > :first-of-type {
                      margin-bottom: 0;
                    }
                  }
                  // ２列目が空の時、１列目の最後のブロック要素からmargin-bottomを削除
                  > .block__column:nth-of-type(1):has(+ .block__column:nth-of-type(2):empty) {
                    > .column__block:last-of-type > .column__blockContent > :first-of-type {
                      margin-bottom: 0;
                    }
                  }
                  // ２列表示の場合はどちらの列も、最後のブロック要素からmargin-bottomを削除
                  @media ($ipadv) {
                    > .block__column {
                      > .column__block:last-of-type > .column__blockContent > :first-of-type {
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      // ブロック要素ではない場合、rmMbTargetに対してmargin-bottomを削除
      &:not(.blockArea) {
        .rmMbTarget {
          margin-bottom: 0;
        }
      }
    }
  }
}

.block {
  width: 100%;
  margin: 0;
  .blockContent {
    position: relative;
    margin: 0 auto;
    width: 100%;
    // max-width: 692px;
    &.title {
      max-width: 672px;
      width: 100%;
    }
    &.image {
      width: 100%;
    }
    &.button {
      max-width: 672px;
      width: 100%;
      display: flex;
    }
    &.shopLink {
      max-width: 672px;
      width: 100%;
      display: flex;
    }
    &.text {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 672px;
      justify-content: center;
    }
    &.line {
      max-width: 672px;
      width: 100%;
    }
    &.table {
      max-width: 672px;
      width: 100%;
    }
    &.column {
      max-width: 672px;
      width: 100%;
    }
    &.introduction {
      max-width: 672px;
      width: 100%;
    }
    &.referenceMenu {
      width: $menu_container_width_percent;
      max-width: $menu_container_max_width;
      text-align: center;
      // メニューコンテナのサイドバー分の逃げ設定
      &.use_category_selector {
        // メニューコンテナ最大幅 + サイドバー最大幅 + アルファ　未満の場合
        @media only screen and (max-width: calc(#{$menu_container_max_width} + #{$category_selector_max_width} * 2 + #{$double_padding})) {
          padding-left: $double_padding;
        }
        // メニューコンテナ最大幅　未満の場合
        @media only screen and (max-width: #{$menu_container_max_width}) {
          $padding: 22px;
          padding-left: calc(#{$category_selector_width} / 2 + #{$padding});
        }
      }
      
      // 写真右レイアウト時の指定
      .image_right {
        // カテゴリーバー
        .category_bar {
          &__image {
            float: right;
            margin-left: $basic_padding;
            margin-right: 0;
            img {
              object-position: right top;
            }
          }
          &__expand_button,
          &__shrink_button {
            text-align: left;
          }
        }
        // メニュー
        .menu_item {
          &__image {
            float: right;
            margin-left: $basic_padding;
            margin-right: 0;
            img {
              object-position: right top;
            }
          }
          &__expand_button,
          &__shrink_button {
            text-align: left;
          }
        }
      }
    }
    &.googleReview {
      max-width: 672px;
      width: 100%;      
    }
  }

  /* 見出し */
  &__title {
    width: 84%;
    margin: 0 auto 20px;
    @media ($ipadv) {
      width: 100%;
    }
    font-size: 20px !important;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
    line-height: 1.7;
    color: var(--functional-color);
    &.__modalStyle__ {
      &.secHeading {
        color: var(--functional-color-modal);
      }
    }
  }
  /* テキスト */
  &__text {
    width: 84%;
    margin: 0 auto 48px;
    @media ($ipadv) {
      width: 100%;
    }
    font-size: 14px;
    line-height: 1.7;
    color: var(--functional-color);
    &.__modalStyle__ {
      color: var(--functional-color-modal);
    }
  }
  /* 画像・動画 */
  &__eyecatch {
    max-width: 672px;
    margin: 0 auto 30px;
    &__inner {
      position: relative;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      &__img {
        width: 100% !important;
        &.width90 {
          // あえてページ全体の余白を考慮したwidthにしています
          width: 84% !important;
          @media ($ipadv) {
            width: 100% !important;
          }
        }
        &.width50 {
          width: 50% !important;
          &:before {
            content: '';
            display: block;
            padding-top: 50%;
            position: absolute;
          }
          @media ($ipadv) {
            width: 100% !important;
          }
        }
        &.objectFitContents {
          @media ($ipadv) {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }
      }
      &__picture {
        @media ($ipadv) {
          width: 100%; /* コンテナの横幅（編集画面の横幅に合わせ） */
          height: 183px; /* コンテナの縦幅（編集画面の高さに合わせ） */
          overflow: hidden; /* はみ出た部分を隠す */
          display: flex; /* Flexboxを使用して画像を中央に配置 */
          justify-content: center; /* 横方向の中央揃え */
          align-items: center; /* 縦方向の中央揃え */
        }
      }
    }
    &__video {
      position: relative;
      margin: 0 auto;
      width: 84% !important;
      @media ($ipadv) {
        width: 100% !important;
      }
      &::before {
        content: '';
        display: block;
        padding-top: calc(((9 / 16) * 100%));
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  /* 区切り線 */
  &__line {
    width: 84%;
    margin: 0 auto 30px;
    @media ($ipadv) {
      width: 100%;
    }
    border-top: 1px solid var(--accent-color);
    &.__modalStyle__ {
      border-top: 1px solid var(--accent-color-modal);
    }
  }
  /* ボタン */
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 0.5px solid var(--attention-color);
    height: auto;
    padding: 10px 20px;
    max-width: 84%;
    @media ($ipadv) {
      max-width: 100%;
    }
    text-align: center;
    font-size: 14px;
    color: var(--base-color) !important;
    background-color: var(--attention-color);
    text-decoration: none;
    transition: all 0.4s ease;
    margin: 0 auto 48px;
    &.__modalStyle__ {
      border: 0.5px solid var(--attention-color-modal);
      color: var(--base-color-modal) !important;
      background-color: var(--attention-color-modal);
    }
    cursor: pointer;
  }
  /* 店舗リンク */
  &__shopLink {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 0.5px solid var(--attention-color);
    height: auto;
    padding: 10px 20px;
    max-width: 84%;
    @media ($ipadv) {
      max-width: 100%;
    }
    text-align: center;
    font-size: 14px;
    color: var(--base-color) !important;
    background-color: var(--attention-color);
    text-decoration: none;
    transition: all 0.4s ease;
    margin: 0 auto 48px;
    cursor: pointer;
    &.__modalStyle__ {
      border: 0.5px solid var(--attention-color-modal);
      color: var(--base-color-modal) !important;
      background-color: var(--attention-color-modal);
    }
  }
  /* 表 */
  &__table {
    width: 84%;
    margin: 0 auto 30px;
    @media ($ipadv) {
      width: 100%;
    }
    &__tr {
      color: var(--functional-color);
      border-top: 1px solid var(--accent-color);
      &:last-of-type {
        border-bottom: 1px solid var(--accent-color);
      }
    }
    &__td {
      vertical-align: top;
      word-break: break-word;
      font-size: 14px;
      &.__title {
        width: 100%;
        display: block;
        @media ($ipadv) {
          width: 25%;
          display: table-cell;
        }
        p {
          line-height: 1.7;
          margin: 8px 8px 4px 8px;
          @media ($ipadv) {
            margin: 8px;
          }
        }
      }
      &.__text {
        width: 100%;
        display: block;
        @media ($ipadv) {
          width: 75%;
          display: table-cell;
        }
        p {
          line-height: 1.7;
          margin: 0 8px 8px 24px;
          @media ($ipadv) {
            margin: 8px;
          }
        }
      }
    }
    &.__modalStyle__ {
      > .block__table__tr {
        color: var(--functional-color-modal);
        border-top: 1px solid var(--accent-color-modal);
        &:last-of-type {
          border-bottom: 1px solid var(--accent-color-modal);
        }
      }
    }
  }
  /* 列レイアウト */
  &__columnGroup {
    display: block;
    @media ($ipadv) {
      display: flex;
    }
    &.column2 {
      > .block__column {
        width: 100%;
        @media ($ipadv) {
          width: 50%;
        }
      }
    }
  }
  &__column {
    &:first-of-type {
      padding: 0;
      @media ($ipadv) {
        padding: 0 10px 0 0;
      }
    }
    &:last-of-type {
      padding: 0;
      @media ($ipadv) {
        padding: 0 0 0 10px;
      }
    }
    > .column__block {
      width: 100%;
      > .column__blockContent {
        &.title {
          > .secHeading::after {
            content: none;
          }
        }
        &.button {
          display: flex;
        }
        &.shopLink {
          max-width: 672px;
          width: 100%;
          display: flex;
        }
      }
    }
  }
  /* 紹介ブロック */
  &__introductionGroup {
    margin: 0 auto 30px;
  }
  &__introduction {
    width: 100%;
    display: flow-root;
    padding: 30px 0;
    margin: 0 auto;
    position: relative;
    @media ($ipadv) {
      padding: 30px 16px;
    }
    &.visualRight {
      > .block__introduction__section {
        &.__sectionTextInfo {
          @media ($ipadv) {
            float: left !important;
          }
        }
        &.__sectionVisual {
          @media ($ipadv) {
            float: right !important;
          }
        }
      }
    }
    &::before {
      content: "";
      border-top: 1px solid var(--accent-color);
      width: 84%;
      position: absolute;
      left: 50%;
      top: 0px;
      transform: translate(-50%, -50%);
      @media ($ipadv) {
        width: 100%;
      }
    }
    &:last-of-type {
      &::after {
        content: "";
        border-top: 1px solid var(--accent-color);
        width: 84%;
        position: absolute;
        left: 50%;
        bottom: 0px;
        transform: translate(-50%, -50%);
        @media ($ipadv) {
          width: 100%;
        }
      }
    }
    &__section {
      &.__sectionTextInfo {
        width: 84%;
        margin: 0 auto 24px;
        padding: 0 16px;
        float: none;
        @media ($ipadv) {
          padding: 0;
        }
      }
      &.__sectionVisual {
        width: 100%;
        float: none;
      }
      &.w50 {
        &.__sectionTextInfo {
          @media ($ipadv) {
            width: calc(50% - 12px);
            margin-bottom: 0;
            float: right;
          }
        }
        &.__sectionVisual {
          @media ($ipadv) {
            width: calc(50% - 12px);
            margin-bottom: 0;
            float: left;
          }
        }
      }
      &.w100 {
        &.__sectionTextInfo {
          margin: 0 auto;
          @media ($ipadv) {
            width: 100%;
            float: right;
          }
        }
        &.__sectionVisual {
          @media ($ipadv) {
            float: right;
          }
        }
      }
    }
    &__title {
      font-size: 16px;
      line-height: 1.7;
      margin-top: 0;
      margin-bottom: 16px;
      color: var(--functional-color);
    }
    &__price {
      display: inline-block;
      width: auto;
      font-size: 20px;
      line-height: 28px;
      color: var(--attention-color);
      margin-top: 0;
      margin-bottom: 10px;
    }
    &__text {
      font-size: 13px;
      line-height: 1.7;
      margin: 0;
      color: var(--functional-color);
    }
    &__image {
      width: 100%;
      margin: 0 auto;
      > .introductionBlock__eyecatch__inner {
        margin-top: 0;
        position: relative;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        .introductionBlock__eyecatch__inner__img {
          height: 100%;
          width: 100%;
          @media #{ $pcview } {
            height: auto;
          }
          @media ($ipadv) {
            width: auto;
          }
          &.width90 {
            // あえて各ブロックでのまとまりを考慮したwidthにしています
            width: calc(84%) !important;
            @media ($ipadv) {
              width: 100%;
            }
            @media #{ $pcview } {
              width: auto;
            }
          }
          &.width50 {
            width: 50%;
            @media ($ipadv) {
              width: 100%;
            }
            @media #{ $pcview } {
              width: auto;
            }
          }
        }
        > .introductionBlock__eyecatch__video {
          position: relative;
          margin: 0 auto;
          width: 84% !important;
          @media ($ipadv) {
            width: 100% !important;
          }
          &::before {
            content: '';
            display: block;
            padding-top: calc(((9 / 16) * 100%));
          }
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    &.__modalStyle__ {
      &::before {
        border-top: 1px solid var(--accent-color-modal);
      }
      &:last-of-type {
        &::after {
          border-top: 1px solid var(--accent-color-modal);
        }
      }
      .block__introduction__title {
        color: var(--functional-color-modal);
      }
      .block__introduction__price {
        color: var(--attention-color-modal);
      }
      .block__introduction__text {
        color: var(--functional-color-modal);
      }
    }
  }
  /* Googleクチコミ */
  &__googleReview {
    width: 84%;
    margin: 0 auto 48px;
    @media ($ipadv) {
      width: 100%;
    }
    height: 300px;
    overflow-y: scroll;
    padding: 10px;
    background-color: #fff;
    .googleReview {
      &__item {
        padding: 10px;
        border-top: 1px solid #dadce0;
        &:last-of-type {
          border-bottom: 1px solid #dadce0;
        }
        // レビュアー名
        &__reviewerName {
          font-size: 15px;
          line-height: 21px;
          margin: 0;
        }
        // 星評価と投稿日時
        &__starRatingAndPassedDatetime {
          margin: 0 0 5px 0;
          display: flex;
          align-items: center;
        }
        // 星評価
        &__starRating {
          line-height: 21px;
          display: flex;
          gap: 3px;
          &__star {
            margin: 0;
            &::after {
              content: '★';
              color: #f1f3f4;
              height: 16px;
              width: 16px;
            }
          }
          &.rating1 {
            .star1 {
              &::after {
                content: '★';
                color: #fbbc04;
              }
            }
          }
          &.rating2 {
            .star1,
            .star2 {
              &::after {
                content: '★';
                color: #fbbc04;
              }
            }
          }
          &.rating3 {
            .star1,
            .star2,
            .star3 {
              &::after {
                content: '★';
                color: #fbbc04;
              }
            }
          }
          &.rating4 {
            .star1,
            .star2,
            .star3,
            .star4 {
              &::after {
                content: '★';
                color: #fbbc04;
              }
            }
          }
          &.rating5 {
            .star1,
            .star2,
            .star3,
            .star4,
            .star5 {
              &::after {
                content: '★';
                color: #fbbc04;
              }
            }
          }
        }
        // 投稿日時
        &__passedDatetime {
          font-size: 14px;
          line-height: 21px;
          margin: 0 0 0 10px;
          color: #70757a;
        }
        // レビュー内容
        &__comment {
          font-size: 14px;
          line-height: 21px;
          white-space: pre-wrap;
          margin: 0;
          &.checkEllipsis {
            overflow: hidden;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            line-clamp: 3;
          }
          &.ellipsis {
            + .googleReview__item__moreBtn {
              display: flex;
            }
          }
        }
        &__moreBtn {
          display: none;
          font-size: 14px;
          line-height: 21px;
          justify-content: end;
          color: var(--ion-color-primary, #3880ff);
          cursor: pointer;
        }
      }
    }
  }
}

// ↓↓↓ホームのブロック部分で横幅縮めているが、不要と判断し一旦コメントアウト
// /********************************/
// /* ページごとのブロックレイアウト調整 */
// /********************************/

// // ホームのブロックレイアウト
// .topAbout__block,
// .topMenu__block,
// .topStaff__block,
// .topShop__block,
// .topGallery__block,
// .topNews__block,
// .topCampaign__block,
// .topFreeSimple__block {
//   .blockContent {
//     &.title {
//       // width: 90%;
//       // @media ($ipadv) {
//       //   width: 100%;
//       // }
//     }
//     &.text {
//       // width: 90%;
//       // @media ($ipadv) {
//       //   width: 100%;
//       // }
//     }
//     &.image {
//       // width: 100%;
//     }
//     &.line {
//       // width: 90%;
//       // @media ($ipadv) {
//       //   width: 100%;
//       // }
//     }
//     &.button {
//       // width: 90%;
//     }
//   }
// }

// 緊急ニュースのブロックレイアウト（通常ページと異なる余白を取っているため、特別に指定）
.emergencyNewsBlock {
  .blockContent {
    &.title,
    &.text,
    &.line,
    &.table {
      width: 100%;
      padding: 0 30px;
      @media ($ipadv) {
        padding: 0 40px;
      }
      > :first-of-type {
        width: 100%;
      }
    }
    // 
    &.image {
      width: 100%;
      @media ($ipadv) {
        padding: 0 40px;
      }
      .width90 {
        // 緊急ニュースの余白を考慮したwidthにしています
        width: calc(100% - 60px);
        @media ($ipadv) {
          width: 100%;
        }
      }
      .block__eyecatch {
        max-width: unset;
      }
      .block__eyecatch__video {
        width: calc(100% - 60px) !important;
        @media ($ipadv) {
          width: 100% !important;
        }
      }
    }
    &.button,
    &.shopLink {
      width: 100%;
      padding: 0 30px;
      @media ($ipadv) {
        padding: 0 40px;
      }
    }
    // ２列レイアウト
    &.column {
      max-width: unset;
      width: 100%;
      > .block__columnGroup {
        width: 100%;
        > .block__column {
          &:nth-of-type(1) {
            @media ($ipadv) {
              padding: 0 12px 0 40px;
            }
          }
          &:nth-of-type(2) {
            @media ($ipadv) {
              padding: 0 40px 0 12px;
            }
          }
        }
      }
    }
    // 紹介ブロック
    &.introduction {
      width: 100%;
      padding: 0;
      @media ($ipadv) {
        padding: 0 40px;
      }
      > .block__introductionGroup > .block__introduction {
        width: 100%;
        margin: 0;
        .width90 {
          // 緊急ニュースの余白を考慮したwidthにしています
          width: calc(100% - 60px) !important;
          @media ($ipadv) {
            width: 100% !important;
          }
        }
      }
    }
  }

  // ２列レイアウト内の各ブロックはwidth100%で表示
  .column__blockContent {
    &.title,
    &.text,
    &.line {
      width: 100%;
      padding: 0 30px;
      @media ($ipadv) {
        padding: 0;
      }
      > :first-of-type {
        width: 100%;
      }
    }
    // 
    &.image {
      width: 100%;
      .width90 {
        // 緊急ニュースの余白を考慮したwidthにしています
        width: calc(100% - 60px) !important;
        @media ($ipadv) {
          width: 100% !important;
        }
      }
      .block__eyecatch {
        max-width: unset;
      }
      .block__eyecatch__video {
        width: calc(100% - 60px) !important;
        @media ($ipadv) {
          width: 100% !important;
        }
      }
    }
    &.button,
    &.shopLink {
      width: 100%;
      padding: 0 30px;
      @media ($ipadv) {
        padding: 0;
      }
    }
  }
}

ion-content {
  --ion-background-color: var(--base-color);
  &.__modalStyle__ {
    --ion-background-color: var(--base-color-modal);
  }
}

// 再インストールアラートデザイン
.reInstallMsg > .alert-wrapper > .alert-message {
  font-size: 14px;
}

// OSが古いよアラートデザイン
.oldOSAlertMsg > .alert-wrapper > .alert-message {
  font-size: 14px;
  text-align: inherit;
}

.failureCreateAccountMsg > .alert-wrapper > .alert-message {
  -webkit-user-select: auto;
  -khtml-user-select: auto;
  -ms-user-select: auto;
  -moz-user-select: auto;
  user-select: auto;
  font-size: 14px;
  text-align: inherit;
}

.deleteCustomerMsg > .alert-wrapper > .alert-message {
  max-height: 100%;
  font-size: 14px;
}

/********************************/
/* プレビューの非表示メッセージ */
/********************************/
.hiddenPageAlert {
  border: 1px solid #ccc;
  width: 70%;
  text-align: center;
  margin: 0 auto;
  border-radius: 40px;
  padding: 10px;
  font-family: '游ゴシック', 'Yu Gothic', '游ゴシック体', YuGothic, sans-serif;
  background: #eee;
  margin-bottom: 30px;
  @media #{ $pcview } {
    width: 40%;
  }
  &__title__ForSave,
  &__title__ForPublic {
    color: red;
    font-size: 16px;
    font-weight: 600;
  }
}

ion-modal.stack-modal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

.shopDetailModal {
  &__main {
    width: 100%;
    height: 100%;
  }
  &__header {
    > .header {
      margin-top: 44px;
    }
  }
}

.modalContent {
  display: none;
  height: 100%;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  &:last-of-type {
    display: block;
  }
}

/* ---------- カテゴリ選択サイドバー ----------*/

::ng-deep .category_selector_container {
  position: sticky;
  top: calc(80px + env(safe-area-inset-top) + #{$basic_padding}); //ヘッダ高 + 基本マージン
  z-index: 1;
  @media #{ $pcview } {
    top: calc(105px + env(safe-area-inset-top) + #{$basic_padding});
  }
  //
  .category_selector {
    position: absolute;
    top: 0;
    left: 0;
    min-width: $category_selector_width;
    max-width: $category_selector_max_width;
    width: calc((100vw - min(#{$menu_container_max_width}, 100vw * #{$menu_container_width_value})) / 2);
    height: calc(100vh - (80px + env(safe-area-inset-top) + #{$basic_padding}));
    @media #{ $pcview } {
      height: calc(100vh - (105px + env(safe-area-inset-top) + #{$basic_padding}));
    }
    border-radius: 0 10px 0 0;
    background: var(--base-color);
    color: var(--functional-color);
    z-index: 10;
    opacity: 1;
    overflow: hidden;
    transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
    box-shadow: 1px 0px 8px 0 rgba(var(--functional-color-rgb), 0.3);
    // リサイズボタンによるサイズ変更
    &.wide {
      max-width: 600px;
      width: calc(100vw - #{$double_padding}) !important;
      .category_selector_header .title {
        opacity: 1;
      }
      .categorys .category .category_name {
        font-size: 12px;
      }
    }
    .category_selector_header {
      position: relative;
      width: 100%;
      height: 32px;
      margin: 0;
      padding: 0;
      border-bottom: 1px solid rgba(var(--functional-color-rgb), 0.3);
      // リサイズボタン
      .resize_button {
        position: relative;
        width: 100%;
        height: 100%;
        background: var(--base-color);
        cursor: pointer;
        .expand_mark,
        .shrink_mark {
          position: absolute;
          content: '';
          width: 8px;
          height: 8px;
          border-right: 2px solid var(--functional-color);
          border-top: 2px solid var(--functional-color);
          top: 50%;
          right: 10px;
        }
        .expand_mark {
          transform: translateY(-50%) rotate(45deg);
        }
        .shrink_mark {
          transform: translateY(-50%) rotate(-135deg);
        }
      }
      .title {
        position: absolute;
        width: 100%;
        padding: 0 20px;
        text-align: center;
        overflow: hidden;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        white-space: nowrap;
        pointer-events: none;
        font-size: 12px;
        color: #999;
        transition: opacity 0.5s;
      }
    }
    .categorys {
      position: relative;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      scrollbar-width: none;
      &::after {
        //Firefoxで、flexコンテナ内のpaddingが効かないため、その対処策
        display: block;
        content: '';
        padding-bottom: 200px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      .category {
        $line_height: 1.3em;
        position: relative;
        line-height: $line_height;
        flex: 0 0 calc(4.5 * #{$line_height});
        padding: calc(#{$line_height} / 2) 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(var(--functional-color-rgb), 0.2);
        overflow: hidden;
        background: var(--base-color);
        color: var(--functional-color);
        cursor: pointer;
        &:last-child {
          border: none;
        }
        &.all {
          position: sticky;
          top: 0;
          z-index: 3;
          border-bottom: 1px solid rgba(var(--functional-color-rgb), 0.4);
        }
        &.selected {
          &::before {
            position: absolute;
            content: '';
            background: var(--accent-color);
            width: 4px;
            height: 100%;
            top: 0;
            right: 0;
          }
          .category_name {
            font-weight: bold;
          }
        }
        .category_name {
          width: 100%;
          max-height: 100%;
          padding: 0 $quarter_padding;
          text-align: center;
          font-size: 11px;
          line-height: $line_height;
          max-height: calc(#{$line_height} * 3);
          color: var(--functional-color);
          overflow: hidden;
        }
      }
    }
  }
}

/* ---------- カテゴリー ----------
  メニュー群の先頭につけるカテゴリー説明用の帯。　
  （.menu_list > category の先頭に設置する）
*/

::ng-deep .category_bar {
  position: relative;
  width: 100%;
  display: flex;
  padding: 30px 0;
  word-break: break-word;
  white-space: pre-wrap;
  &:has(+ .menu_item) {
    &::after {
      content: "";
      border-top: 1px solid var(--accent-color);
      width: 100%;
      position: absolute;
      left: 50%;
      bottom: 0px;
      transform: translate(-50%, -50%);
    }
  }

  &.see_more_button_required {
    padding-bottom: $buttons_height;
    .category_bar__content {
      &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 2.8em;
        left: 0;
        bottom: 0;
        background: linear-gradient(to bottom, rgba(var(--base-color-rgb), 0), rgba(var(--base-color-rgb), 1) 100%);
      }
    }
  }

  &.expand {
    .category_bar__content {
      max-height: 100vh;
      &::after {
        content: none;
      }
    }
  }

  &__content {
    position: relative;
    width: 100%;
    min-height: 30px;
    max-height: 150px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    @media #{ $pcview } {
      max-height: 220px;
    }
  }

  &__content_inner_fake {
    height: 100%;
    max-height: 220px;
    position: absolute;
    width: 100%;
  }

  &__name {
    font-size: 18px;
    line-height: 1.4em;
    text-align: left;
    color: var(--functional-color);
    white-space: initial;
    text-align: center;
    @media #{ $pcview } {
      font-size: 20px;
      margin-bottom: 0.5em;
    }
  }

  &__details {
    width: 100%;
  }

  &__image {
    @mixin photo_size($size) {
      width: $size;
      height: $size;
      margin-left: 0;
      margin-right: $basic_padding;
      z-index: 1;
      float: left;
    }
    @include photo_size(90px);
    @media #{ $pcview } {
      @include photo_size(150px);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left top;
    }
  }

  &__desc {
    font-size: 12px;
    line-height: 1.6em;
    text-align: left;
    color: var(--functional-color);
    margin-top: 0.5em;
  }

  // もっと見るボタン
  &__buttons {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $buttons_height;
    .category_bar__expand_button,
    .category_bar__shrink_button {
      flex-grow: 1;
      font-size: 12px;
      pointer-events: auto;
      user-select: none;
      margin: 0;
      padding: 0;
      color: var(--functional-color);
      opacity: 0.5;
      background: transparent;
      text-align: right;
    }
  }

  &:not(.see_more_button_required) {
    .category_bar__buttons {
      display: none;
    }
  }
}

/* ---------- メニュー ---------- */

::ng-deep .menu_item {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 30px 0px;
  text-align: start;
  word-break: break-word;
  white-space: pre-wrap;
  &::after {
    content: "";
    border-top: 1px solid var(--accent-color);
    width: 100%;
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, -50%);
  }
  &.no_category {}

  &.see_more_button_required {
    padding-bottom: $buttons_height;
    .menu_item__content {
      &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 2em;
        left: 0;
        bottom: 0;
        background: linear-gradient(to bottom, rgba(var(--base-color-rgb), 0), rgba(var(--base-color-rgb), 1) 100%);
      }
    }
  }

  &.expand {
    .menu_item__content {
      max-height: 100vh;
      &::after {
        content: none;
      }
    }
  }

  &__content {
    position: relative;
    width: 100%;
    min-height: 30px;
    max-height: 170px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    @media #{ $pcview } {
      max-height: 260px;
    }
  }

  &__content_inner_fake {
    height: 100%;
    max-height: 260px;
    position: absolute;
    width: 100%;
  }

  // メニュー名
  &__title,
  &__name {
    width: 100%;
    font-size: 16px;
    line-height: 1.7;
    color: var(--functional-color);
    margin-top: 0;
    margin-bottom: 0.3em;
    white-space: initial;
    @media #{ $pcview } {
      // font-size: 18px;
      margin-bottom: 0.5em;
    }
    // カテゴリなし
    &.no_category {
      width: auto;
      font-size: 18px;
      margin-bottom: 0;
    }
    //「OPTION」メニューマーク
    &.option_menu::before {
      position: relative;
      content: 'OPTION';
      width: 50px;
      height: 1.2em;
      font-size: 13px;
      color: var(--attention-color);
      border: 1px solid var(--attention-color);
      border-radius: 10px;
      padding: 1px 4px;
      margin-right: 0.3em;
    }
  }

  &__details {
    width: 100%;
  }

  // 価格、所要時間
  &__price_and_time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: $quarter_padding;
    font-size: 12px;
    line-height: 1.4em;
    white-space: initial;
    @media #{ $pcview } {
      font-size: 14px;
      margin-bottom: $half_padding;
    }
  }
  &__price {
    font-size: 20px;
    line-height: inherit;
    color: var(--attention-color);
  }
  &__time {
    font-size: inherit;
    line-height: inherit;
    color: var(--functional-color);
    display: flex;
    align-items: center;
    .icon_time {
      display: block;
      width: 1.3em;
      height: 1.3em;
      background: var(--functional-color);
      mask: url('/assets/images/icons/time-outline.svg') 0 0.1em / 1.2em 1.2em no-repeat;
    }
  }

  // 画像、説明文
  &__image_and_description {
    display: block;
  }
  &__image {
    @mixin photo_size($size) {
      width: $size;
      height: $size;
      margin-left: 0;
      margin-right: $basic_padding;
      z-index: 1;
      float: left;
    }
    @include photo_size(90px);
    @media #{ $pcview } {
      @include photo_size(150px);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left top;
    }
  }
  &__desc {
    width: 100%;
    display: block;
    font-size: 12px;
    line-height: 1.7;
    color: var(--functional-color);
    margin-top: 10px;
    margin-bottom: 0;
    text-align: left;
    &.no_category {
      line-height: 20px;
      margin-top: 15px;
      margin-bottom: 0;
      text-align: left;
      @media #{ $pcview } {
        margin-top: 20px;
      }
    }
  }
  // もっと見るボタン
  &__buttons {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $buttons_height;
    .menu_item__expand_button,
    .menu_item__shrink_button {
      flex-grow: 1;
      font-size: 12px;
      pointer-events: auto;
      user-select: none;
      margin: 0;
      padding: 0;
      color: var(--functional-color);
      opacity: 0.5;
      background: transparent;
      text-align: right;
    }
  }

  &:not(.see_more_button_required) {
    .menu_item__buttons {
      display: none;
    }
  }
}

// アクセス期限アラート
.inaccessibleAlertMsg > .alert-wrapper > .alert-message {
  text-align: left;
}